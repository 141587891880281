import $ from 'jquery'
import 'magnific-popup'
import './classes/polyfills.class.js'
import AnchorScroller from './classes/anchor-scroller'
import AOS from 'aos'
import CollapsibleItem from './classes/collapsible-item.class.js'
import FixedHeader from './classes/fixed-header.class'
import FixedIsi from './classes/fixed-isi.js'
import FormBehavior from './classes/form-behavior.class.js'
import MainMenu from './classes/main-menu.class.js'
import Carousel from './classes/carousel.class.js'
import Modal from './classes/modal.class.js'
import Violator from './classes/violator.class'
import MediaCenter from './classes/media-center.class'

// Redirects for hash pages
const currentPage = window.location.pathname + window.location.hash

const redirects = [
  {
    url: '/about/#force',
    redirect: '/about-us/the-ionis-story'
  },
  {
    url: '/about/#team',
    redirect: '/about-us/our-leadership'
  },
  {
    url: '/about/#akcea',
    redirect: '/about-us/the-ionis-story#akcea'
  }
]

redirects.forEach(redirect => {
  if (currentPage === redirect.url) {
    window.location = redirect.redirect
  }
})


// Initialize Animate on Scroll
AOS.init({
  duration: 1200,
})

// Initialize AnchorScroller
const scroll = new AnchorScroller(
  $('header[role=banner]').height(),
  $('header[role=banner]').height(),
  768
)
scroll.init()

$(window).on('resize', function () {
  if ($('html').hasClass('enable_sticky_desktop')) {
    scroll.desktopOffset = $('header[role=banner]').height()
  }

  if ($('html').hasClass('enable_sticky_desktop')) {
    scroll.mobileOffset = $('header[role=banner]').height()
  }
}).trigger('resize')




$(() => {

  const isi = document.querySelector('.isi .fixed')
  if (isi) {
    new FixedIsi()
  }

  const violator = document.querySelector('.violator')
  if (violator) {
    new Violator(violator)
  }

  new FixedHeader()

  const mainMenu = document.querySelector('.layout-container > header')
  if (mainMenu) {
    new MainMenu(mainMenu)
  }

  const mediaCenter = document.querySelector('.media_content_wrapper nav')
  if (mediaCenter) {
    new MediaCenter(mediaCenter)
  }

  // Don't active slideshows on the Layout Builder page
  if (
    $('.block--type--carousel-slide').length
    && !$('.node-page-layout-builder-form').length
  ) {
    $.uniqueSort(
      $('.block--type--carousel-slide')
        .parents('.layout__region')
    )
      .css({
        'min-width': 0
      })
      .each(function () {
        new Carousel(this)
      })
  }

  document.querySelectorAll('.webform-submission-form').forEach(f => {
    new FormBehavior(f)
  })

  document.querySelectorAll('.block--type--collapsible-item').forEach(item => {
    new CollapsibleItem(item)
  })

  if ($('.popup-youtube').length) {
    $('.popup-youtube').magnificPopup({
      type: 'iframe',
      iframe: {
        patterns: {
          youtube: {
            index: 'youtube.com/',
            id: 'v=',
            src: '//www.youtube.com/embed/%id%?autoplay=1&modestbranding=1'
          }
        }
      },
      mainClass: 'mfp-fade',
      removalDelay: 160,
      preloader: false,
      fixedContentPos: false
    })
  }

  // HOMEPAGE
  const isHome = document.querySelector('.page-home') || document.querySelector('.node-46-layout')
  if (isHome) {
    const carouselContainer = document.querySelector('.layout--carousel-layout:nth-of-type(1)')
    const slider = $('.layout--carousel-layout:nth-of-type(1) .slick-slider')
    const scrollDown = document.querySelector('.hover-button ~ p')
    const scrollDownClone = scrollDown.cloneNode(true)
    scrollDownClone.classList.add('mobile-only')

    carouselContainer.appendChild(scrollDownClone)

    slider.slick('slickSetOption', 'autoplaySpeed', 5000, true);
    slider.slick('slickSetOption', 'autoplay', true, true);
    slider.slick('slickSetOption', 'arrows', true, true);

    const viewList = document.querySelectorAll('.feed-items__view .views-row')
    const newsList = []

    const sanitizePreview = (str) => {
      return str.replace(/&nbsp;/g, ' ').replace(/&amp;/g, '&').replace(/&quot;/g, '"').replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&apos;/g, "'").replace('<p>', '').replace('</p>', '')
    }

    if (viewList.length) {
      viewList.forEach(view => {
        const date = view.querySelector('.views-field-field-date').innerText.replace(/\n/g, '').trim()
        const formatedDate = new Date(date)
        const timezone = view.querySelector('.views-field-field-timezone').innerText.replace(/\n/g, '').trim()
        const title = view.querySelector('.views-field-title').innerText.replace(/\n/g, '').trim()
        const preview = sanitizePreview(view.querySelector('.views-field-body').innerText.replace(/\n/g, '').trim())
        const link = view.querySelector('.views-field-field-url a').href

        newsList.push({
          date: `${formatedDate.toLocaleString('en-US', { month: 'long' })} ${formatedDate.getDate()}, ${formatedDate.getFullYear()}`,
          title,
          preview,
          link
        })
      })
    }

    const feedContainer = document.querySelector(".new_release__container")
    feedContainer.innerHTML = ''
    let $slick_slider, slickSettings
    const buildFeed = () => {
      newsList.forEach(newsItem => {
        const template = `<div class="new_release__item shaped__container">
            <div class="new_release__shape shaped__wrapper">
              <span class="new_release__date">${newsItem.date}</span>
              <h3 class="new_release__title" title="${newsItem.title}">${newsItem.title}</h3>
              <p class="new_release__preview">${newsItem.preview}</p>
              <div class="hover-button">
                <img class="" src="/sites/default/files/styles/large/public/2024-08/circle-arrow.png?itok=8iPiZf9M" alt="Read More" width="86" height="86" loading="lazy"> <a class="button" href="${newsItem.link}"><span>Read More</span></a>
              </div>
            </div>
          </div>
        `
        feedContainer.innerHTML += template
      })
  
      const containerClone = feedContainer.cloneNode(true)
      containerClone.classList.add('desktop-only')
  
      feedContainer.classList.add('mobile-only')
      $(feedContainer).parent().addClass('carousel')
      $slick_slider = $(feedContainer)
      slickSettings = {
        accessibility: true,
        arrows: false,
        dots: true,
        speed: 1000,
        mobileFirst: true,
        slidesToShow: 1.15,
        infinite: false,
        responsive: [
          {
            breakpoint: 500,
            settings: {
              slidesToShow: 1.5,
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
            }
          },
          {
            breakpoint: 1025,
            settings: {
              slidesToShow: 3,
            }
          }
        ]
      }
      $slick_slider.slick(slickSettings);
      feedContainer.insertAdjacentElement('afterEnd', containerClone)
    }

    buildFeed()

    // Adjust scroll down button
    const adjustScrollBtnPosition = () => {
      if (window.innerHeight > 1066) return

      const menuHeight = document.querySelector('.menu-container').offsetHeight
      const scrollDown = document.querySelector('.hover-button ~ p')
      const scrollDownSpan = scrollDown.querySelector('span')
      const scrollDownImg = scrollDown.querySelector('img')
      scrollDown.style.top = ''
      scrollDown.style.right = ''
      scrollDownSpan.style.color = ''
      scrollDownImg.style.filter = ''

      const availableContentHeight = window.innerHeight - menuHeight
      const sectionContainer = document.querySelector('.building-on__container')

      scrollDown.style.top = `${availableContentHeight - 50}px` 
      scrollDown.style.bottom = `initial`

      if (availableContentHeight < 700) {
        const currentScrollRight = window.getComputedStyle(scrollDown).right.replace('px', '')
        scrollDown.style.top = `${availableContentHeight - 30}px` 
        scrollDown.style.right = `${currentScrollRight - 50}px` 
     
      }

      if (window.innerHeight < 610) {
        scrollDownSpan.style.color = '#53565a'
        scrollDownImg.style.filter = 'none'
      }

      window.addEventListener('scroll', (e)=> {
        const scrollTop = window.scrollY;
        const availableContentHeight = window.innerHeight - menuHeight;
        let newTop = availableContentHeight - 50 + scrollTop;
        const containerBottom = sectionContainer.getBoundingClientRect().bottom;

        if (scrollDown.offsetTop > 520 && window.innerWidth > 1024) {
          scrollDownSpan.style.color = 'white'
          scrollDownImg.style.filter = 'brightness(400%)'
        } else {
          scrollDownSpan.style.color = '#53565a'
          scrollDownImg.style.filter = 'none'
        }

        if (scrollDown.getBoundingClientRect().bottom >= containerBottom - 20 && newTop > containerBottom) return

        if (newTop >= 970) {
          newTop = 970
        }

        if (newTop >= containerBottom - sectionContainer.offsetTop) {
          scrollDown.classList.add('fade-out')
        } else {
          scrollDown.classList.remove('fade-out')
        }

        scrollDown.style.top = `${newTop}px`;

       
      })
    }
    adjustScrollBtnPosition()
    
   
    $(window).on('resize', function() {
      if ($(window).width() > 1024) {
        adjustScrollBtnPosition()
        return
      }
    });
  }

  // Adjust the page news to show as a card to the original article

  const isNewsPage = window.location.pathname.includes('/news/')
  if (isNewsPage) {
    const article = document.querySelector('.news-article .wrapper')
    const date = document.querySelector('.news-date div:last-of-type div:last-of-type').innerText.replace(/\n/g, '').trim()
    const formatedDate = new Date(date)
    const fomratedDateText = `${formatedDate.toLocaleString('en-US', { month: 'long' })} ${formatedDate.getDate()}, ${formatedDate.getFullYear()}`
    const title = document.querySelector('.news-title h1').innerText.replace(/\n/g, '').trim()
    const preview = document.querySelector('.news-body p').innerText.replace(/\n/g, '').trim()
    const link = document.querySelector('.news-url a').href

    const template = `
      <div class="new_release__item shaped__container">
        <div class="new_release__shape shaped__wrapper">
          <span class="new_release__date">${fomratedDateText}</span>
          <h3 class="new_release__title" title="${title}">${title}</h3>
          <p class="new_release__preview">${preview}</p>
          <a href="${link}" class="new_release__link" title="Read More">
            <span>Read More</span>
            <img src="/sites/default/files/styles/large/public/2024-08/circle-arrow.png?itok=8iPiZf9M" alt="Read More" width="43" height="43" loading="lazy">
          </a>
        </div>
      </div>
    `
    article.innerHTML = ''
    article.innerHTML = template
  }

  // LEADERSHIP PAGE
  const isLeardershipPage = window.location.pathname === '/about-us/our-leadership' || document.querySelector('.node-11-layout')

  if (isLeardershipPage) {
     // Board Selection
    const viewsElementContainer = document.querySelector('.views-element-container')
    viewsElementContainer.parentElement.classList.add('board-container')
    const containerClass = window.location.search.replace('?board=', 'board-')
    const form = document.querySelector('.views-exposed-form')
    const teamViewWrapper = document.querySelector('.team-view-wrapper')
    const parent = teamViewWrapper.parentElement

    parent.insertBefore(form, teamViewWrapper)

    if (containerClass) viewsElementContainer.classList.add(containerClass)
    const container = document.querySelector('.fieldset-wrapper')

    const executiveTeamOption = document.querySelector('#edit-board div:nth-child(2)')
    const executiveTeamLabel = executiveTeamOption.querySelector('label')
    const isExecutiveActive = executiveTeamOption.querySelector('input').checked
    isExecutiveActive ? executiveTeamOption.classList.add('active')  : null

    const leadershipTeamOption = document.querySelector('#edit-board div:nth-child(3)')
    const leadershipTeamLabel = leadershipTeamOption.querySelector('label')
    const isLeadershipActive = leadershipTeamOption.querySelector('input').checked
    isLeadershipActive ? leadershipTeamOption.classList.add('active')  : null

    const boardOfDirectorsOption = document.querySelector('#edit-board div:nth-child(4)')
    const boardOfDirectorsLabel = boardOfDirectorsOption.querySelector('label')
    
    const isBoardOfDirectorsActive = boardOfDirectorsOption.querySelector('input').checked
    isBoardOfDirectorsActive ? boardOfDirectorsOption.classList.add('active') : null

    const submitButton = document.querySelector('#edit-submit-team')
    let isMobile = window.innerWidth < 1024
    let isUsingMobile = false

    const executiveItems = document.querySelector('.team-view-wrapper.executive-team')
    const bordOfDirectorsItems = document.querySelector('.team-view-wrapper.board-of-directors')
    const leardershipItems = document.querySelector('.team-view-wrapper.leadership-team')

    const boards = [{
      el: executiveItems,
      value: 1
    },
    {
      el: leardershipItems,
      value: 2
    },
    {
      el: bordOfDirectorsItems,
      value: 3
    }]

    const handleBoardOptionsMobile = () => {
      const bordOptionsTemplate = `
        <div class="board__options">
          <div class="option-1 ${isExecutiveActive ? 'active' : ''}">
            ${executiveTeamLabel.outerHTML}
          </div>

          <div class="option-2 ${isLeadershipActive ? 'active' : ''}">
            ${leadershipTeamLabel.outerHTML}
          </div>

          <div class="option-3 ${isBoardOfDirectorsActive ? 'active' : ''}">
            ${boardOfDirectorsLabel.outerHTML}
          </div>
        </div>
      `
      container.innerHTML += bordOptionsTemplate
      const options = document.querySelectorAll('.board__options div')
      const label = document.querySelector('#edit-board label[for="edit-board-1"]')
      const boardOptions = document.querySelector('.board__options')

      options.forEach((option, index) => {
        const input = option.querySelector('input')
        option.addEventListener('click', (e)=> {
          options.forEach(el => {
            el.classList.remove('active')
          })
          
          boards.forEach(board => {
            if (board.value === index + 1) {
              board.el.classList.add('active')
            } else {
              board.el.classList.remove('active')
            }
          })
          option.classList.add('active')
          label.innerText = e.target.innerText
          boardOptions.classList.remove('active')
        })
      })

      const activeEl = document.querySelector('.form-item-board.active')
      activeEl.addEventListener('click', (e)=> {
        if (boardOptions) {
          boardOptions.classList.toggle('active')
        }
      })
      isUsingMobile = true
    }
    
    const handleBoardOptionsDesktop = () => {
      const options = document.querySelectorAll('#edit-board .form-item')
      options.forEach((option, index) => {
        const input = option.querySelector('input')
        option.addEventListener('click', ()=> {
          options.forEach(el => {
            el.classList.remove('active')
          })

          boards.forEach(board => {
            if (board.value === index) {
              board.el.classList.add('active')
            } else {
              board.el.classList.remove('active')
            }
          })
          option.classList.add('active')
        })
      })
    }
    
    isMobile ? handleBoardOptionsMobile() : handleBoardOptionsDesktop()

    window.addEventListener('resize', ()=> {
      isMobile = window.innerWidth < 1024
      if (isMobile) {
        if (isUsingMobile) return
        handleBoardOptionsMobile()
        return
      }

      if (isUsingMobile) {
        const board = document.querySelector('.board__options')
        board.remove()
        isUsingMobile = false
        handleBoardOptionsDesktop()
        return
      }
      handleBoardOptionsDesktop()
    })

    // Employee Details
    const viewsRows = document.querySelectorAll('.views-row')
    
    viewsRows.forEach(row => {
      const button = document.createElement('button')
      button.classList.add('leadership__toggle')

      const name = row.querySelector('.views-field-info')
      const picture = row.querySelector('.views-field-field-picture')
      const role = row.querySelector('.views-field-field-role')
      role.innerHTML = role.innerText
      const description = row.querySelector('.views-field-body')
      if (!containerClass || containerClass === 'board-1') {
        row.appendChild(button)
        row.addEventListener('click', ()=> openLeadershipModal(name, picture, role, description))
      }
      
    })

    const openLeadershipModal = (name, picture, role, description) => {
      const modal = document.createElement('div')
      const nameClass = name.innerText.toLowerCase().replace(/ /g, '-').replace(/\./g, '').replace(/,/g, '')
      modal.classList.add('leadership__modal')
      modal.style.top = `${window.scrollY}px`
      role.classList.add('leadership__modal__role')
      const modalContent = `
      <div class="leadership__modal__wrapper ${nameClass}">
        <div class="leadership__modal__content-wrapper">
          <div class="leadership__modal__content">
              <div class="leadership__modal__close">
                <button class="leadership__modal__close__button">
                  <img src="/themes/custom/envivent/assets/images/global/close-btn.svg" alt="Close" width="24" height="24" loading="lazy">
                </button>
              </div>
              <div class="leadership-content__wrapper">
                <div class="leadership__modal__header">
                  <div class="leadership__modal__picture">
                    ${picture.innerHTML}
                  </div>
                  <div class="leadership__modal__info">
                    <h2 class="leadership__modal__name">${name.innerText}</h2>
      
                    ${role.outerHTML}
                  </div>
                </div>
                <div class="leadership__modal__description">
                  ${description.innerHTML}
                </div>
              </div>
            </div>
          </div>
        </div>
      `
      modal.innerHTML = modalContent
      document.body.appendChild(modal)
      const page = document.querySelector('html')
      page.style.overflow = 'hidden'
      document.body.style.overflow = 'hidden'
  
      const closeButton = modal.querySelector('.leadership__modal__close__button')
      closeButton.addEventListener('click', ()=> {
        document.body.removeChild(modal)
        page.style.overflow = 'auto'
        document.body.style.overflow = 'auto'
      })
      
    }
  }

  
  // Diversity scripts
  const isDeiPage = window.location.pathname === '/about-us/diversity-equity-inclusion' || document.querySelector('.node-12-layout')
  if (isDeiPage) {
    const cards = document.querySelectorAll('.employee_resource__cards .card')

    cards.forEach(card => {
      card.addEventListener('click', ()=> {
        card.classList.toggle('active')
      })
    })
  }


  // Footer 
  const footer = document.querySelector('footer > div')
  const backToTopLayout = `
    <div class="footer-back-to-top">
      <button class="back-to-top__link">
        Back to Top <img src="/themes/custom/envivent/assets/images/global/arrow-down.svg" alt="" width="16" height="23" loading="lazy">
      </button>
    </div>
  ` 

  footer.innerHTML += backToTopLayout
  const backToTop = document.querySelector('.footer-back-to-top')
  backToTop.addEventListener('click', ()=> {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  })


  // Pipeline Page

  const isPipelinePage = window.location.pathname === '/science-and-innovation/pipeline' || document.querySelector('.node-17-layout')

  if (isPipelinePage) {
    const pipelineWrapper = document.querySelector('.pipeline__items')
    let pipelineData = []
    let pipelineIndependentData = []
    let pipelinePartneredData = []
    const lastUpdatedContainer = document.querySelector('#last-updated')
    const lastUpdatedWrapper = document.createElement('div')
    lastUpdatedWrapper.classList.add('wrapper')
    const lastUpdated = document.createElement('p')
    lastUpdatedContainer.appendChild(lastUpdatedWrapper)
    lastUpdatedWrapper.appendChild(lastUpdated)
    let lastUpdate = ''

    // Print Buttons
    const independentButton = document.querySelector('.print_independent__button')
    const partneredButton = document.querySelector('.print_partnered__button')

    // Search Filter funcitonality
    const partnerInput = document.querySelector('.checkbox__option #partnered')
    const ionisOwnedInput = document.querySelector('.checkbox__option #ionis-owned')
    const searchTextInput = document.querySelector('.search-filter__filter input')
    const searchIcon = document.querySelector('.search-filter__filter .search-btn-icon')
 
    // Elements
    const noResults = document.createElement('div')

    const fixDateFormat = (dateStr) => {
      return dateStr.replace(/(\d{2})(\d{2})$/, "$1:$2");
    };

    function parseDatePreservingLocal(dateString) {
      const parsedDate = fixDateFormat(dateString);
      const match = parsedDate.match(/(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}:\d{2})([+-]\d{2}:?\d{2})?/);
      
      if (!match) return null;
  
      const [ , fullDate, time] = match;
      const [year, month, day] = fullDate.split('-').map(Number);
      const [hour, minute, second] = time.split(':').map(Number);
  
      return new Date(year, month - 1, day, hour, minute, second);
    }

    const handleFormatDate = (data) => {
      console.log(data)
      let recentDate = parseDatePreservingLocal(data[0].changed);
  
      data.forEach(item => {
          const itemDate = parseDatePreservingLocal(item.changed);
          if (itemDate > recentDate) {
              recentDate = itemDate;
          }
      });
  
      const formatedDate = `${recentDate.toLocaleString('en-US', { month: 'long' })} ${recentDate.getDate()}, ${recentDate.getFullYear()}`;
      
      return {
          formatedDate,
          fullDate: recentDate
      };
    }

    const getPipelineIndependentData = async () => {
      const response = await fetch('/pipeline/independent?_format=json');
      const data = await response.json();
      return data
    }

    const getPipelinePartneredData = async () => {
      const response = await fetch('/pipeline/partnered?_format=json');
      const data = await response.json();
      return data
    }

  const pipelineItemHeaderTemplate = `
    <div class="pipeline-item__header">
      <div class="pipeline-header__name">
          &nbsp;
      </div>
      <div class="pipeline-header__partner">
          Partner
      </div>
      <div class="pipeline-header__phase mobile-only">
          P1
      </div>
      <div class="pipeline-header__phase tablet-only">
          Phase 1
      </div>
      <div class="pipeline-header__phase mobile-only">
          P2
      </div>
      <div class="pipeline-header__phase tablet-only">
          Phase 2
      </div>
      <div class="pipeline-header__phase mobile-only">
          P3
      </div>
      <div class="pipeline-header__phase tablet-only">
          Phase 3
      </div>
    </div>
  `

  const groupPipelineItems = (data) => {
    const groupedByTherapeuticArea = data.reduce((acc, item) => {
      const area = item.therapeutic_area;
      if (!acc[area]) {
        acc[area] = { items: [] };
      }
      acc[area].items.push(item);
      return acc;
    }, {});

    return groupedByTherapeuticArea
  }

  searchTextInput.addEventListener('input', (e)=> {
    search()
  })

  const setPipelineToggleEvents = () => {
    const pipelineToggle = document.querySelectorAll('.pipeline-item__body')
    pipelineToggle.forEach(el => {
      const toggle = el.querySelector('.pipeline-body__main')
      const content = el.querySelector('.pipeline-body__content')
      toggle.addEventListener('click', ()=> {
        const button = content.querySelector('button')
        el.classList.toggle('active')
        const hasActive = document.querySelector('.pipeline-item__body.active')
        if (hasActive) {
          content.classList.add('is__expanded')
        } else {
          content.classList.remove('is__expanded')
        }

        button.addEventListener('click', ()=> {
          el.classList.remove('active')
        })
      })
    })
  }

  const search = () => {
    noResults.innerHTML = ''
    const isPartnerChecked = partnerInput.checked
    const isIndependentChecked = ionisOwnedInput.checked
    pipelineWrapper.classList.add('has__search')
    const text = searchTextInput.value.toLowerCase()
    const result = pipelineData.filter(item => {
        if (isPartnerChecked && item.partner) {
          return item.drug_name.toLowerCase().includes(text) || item.generic.toLowerCase().includes(text) || item.disease.toLowerCase().includes(text) || item.partner.toLowerCase().includes(text)
        }

        if (isIndependentChecked && !item.partner) {
          return item.drug_name.toLowerCase().includes(text) || item.generic.toLowerCase().includes(text) || item.disease.toLowerCase().includes(text)
        }
    })

    if (result.length === 0) {
      pipelineWrapper.innerHTML = ''
      noResults.classList.add('no__results__message')
      const selectedOptionText = isPartnerChecked ? 'Partnered Programs' : 'Ionis Owned'
      noResults.innerHTML = `No results found for '${searchTextInput.value}' in ${selectedOptionText}.`
      pipelineWrapper.appendChild(noResults)

      return
    } 

    mountPipeline(result)
   }

  const mountPipeline = (data) => {
    noResults.innerHTML = ''
    pipelineWrapper.innerHTML = ''
    const formatedItems = groupPipelineItems(data)

    Object.entries(formatedItems).forEach(([pipelineArea, areaData]) => {
      const pipelineItem = document.createElement('div')
      pipelineItem.classList.add('pipeline__item')
      pipelineItem.classList.add(pipelineArea.toLowerCase().replace(/ /g, '-'))

      const pipelineItemTemplate = `
        <h4>${pipelineArea}</h4>
        <div class="pipeline-content__wrapper">
          ${pipelineItemHeaderTemplate}

          <div class="pipeline-items__body">
            ${areaData.items.map(pipelineItem => `
              <div class="pipeline-item__body visible">
                <div class="pipeline-body__main">
                    <div class="pipeline-body__name">
                        <h4>
                          ${pipelineItem.drug_name}
                        </h4>
                        <p>
                          ${pipelineItem.generic}
                        </p>
                        <p>
                          ${pipelineItem.disease}
                        </p>
                    </div>
                    <div class="pipeline-body__partner" data-partner="${pipelineItem.partner ? 'partner' : 'ionis-owned'}">
                        <p>
                          ${pipelineItem.partner ? pipelineItem.partner : 'Ionis Owned'}
                        </p>
                    </div>
                    <div class="pipeline-body__phase">
                        <div class="pipeline-body__indicator" data-phase="${pipelineItem.phase}">
                            &nbsp;
                        </div>
                    </div>
                </div>
                <div class="pipeline-body__content">
                    ${pipelineItem.description}
                    <p>
                        <button class="close">Close</button>
                    </p>
                </div>
            </div>
            `).join('')}
          </div>
        </div>

      `

      pipelineItem.innerHTML = pipelineItemTemplate
      pipelineWrapper.appendChild(pipelineItem)
    })
    setPipelineToggleEvents()
  }

  Promise.all([getPipelineIndependentData(), getPipelinePartneredData()]).then((values) => {
    pipelineIndependentData = values[0]
    pipelinePartneredData = values[1]

    const data = [...pipelineIndependentData, ...pipelinePartneredData]
    pipelineData = data
    const dates = handleFormatDate(data)
    lastUpdate = dates

    const lastUpdateDate = new Date(dates.formatedDate);
    const limitDate = new Date('March 11, 2025');
    const finalDate = lastUpdateDate < limitDate ? limitDate : lastUpdateDate;
    console.log(lastUpdateDate, limitDate, finalDate)
    const textDate = `${finalDate.toLocaleString('en-US', { month: 'long' })} ${finalDate.getDate()}, ${finalDate.getFullYear()}`
    lastUpdated.innerText = `Ionis pipeline information updated as of ${textDate}`

    if (isNaN(lastUpdateDate.getTime())) {
      lastUpdated.innerText = `Ionis pipeline information updated as of February 19, 2025`
    }

    mountPipeline(pipelineIndependentData)
    ionisOwnedInput.addEventListener('click', ()=> {
      if (searchTextInput.value) {
        ionisOwnedInput.checked = true
        search()
        return;
      }

      mountPipeline(pipelineIndependentData)
      scrollTo({
        top: pipelineWrapper.offsetTop - 100,
        behavior: 'smooth'
      })
    })
  
    partnerInput.addEventListener('click', ()=> {
      if (searchTextInput.value) {
        partnerInput.checked = true
        search()
        return;
      }

      mountPipeline(pipelinePartneredData)
      scrollTo({
        top: pipelineWrapper.offsetTop - 100,
        behavior: 'smooth'
      })
    })
  })

  independentButton.addEventListener('click', async () => {
    const formatedItems = groupPipelineItems(pipelineIndependentData)
    const response = await fetch("/generate-pipeline-pdf", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ filter: 'owned', data: formatedItems, lastUpdate }),
    })

    const blob = await response.blob()
    const url = URL.createObjectURL(blob)
    const downloadEl = document.createElement('a')
    downloadEl.href = url
    downloadEl.download = 'Ionis-owned-pipeline.pdf'
    downloadEl.click()
    downloadEl.remove()
  })

  partneredButton.addEventListener('click', async ()=> {
    const formatedItems = groupPipelineItems(pipelinePartneredData)
    const response = await fetch("/generate-pipeline-pdf", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ filter: 'partnered', data: formatedItems, lastUpdate }),
    })

    const blob = await response.blob()
    const url = URL.createObjectURL(blob)
    const downloadEl = document.createElement('a')
    downloadEl.href = url
    downloadEl.download = 'Ionis-partnered-pipeline.pdf'
    downloadEl.click()
    downloadEl.remove()
  })
}

  // Patient Story Page

  const isPatientStoryPage = window.location.pathname === '/patients-and-advocacy/patient-stories'
  if (isPatientStoryPage) {
    const patientStoriesWrapper = document.querySelector('.patient-stories__section .cards')

    const getStories = async () => {
      const response = await fetch('/patient-stories/data?_format=json');
      const data = await response.json();
      return data
    }

    getStories().then((data) => {
      data.forEach(storyData => {
        const card = document.createElement('div')
        card.classList.add('card')
        const hasVideo = storyData.story.includes('video') || storyData.story.includes('iframe')
        if (hasVideo) {
          card.classList.add('has__video')
        }

        const cardTemplate = `
          <div class="image__wrapper">
            ${storyData.thumb}
          </div>
          <div class="copy__wrapper">
              ${storyData.summary}
              <div class="hover-button">
                  <img class="" src="/sites/default/files/styles/large/public/2024-08/circle-arrow.png?itok=8iPiZf9M" alt="Discover Our Medicines" width="86" height="86" loading="lazy"> <button class="button" href="#"><span>See More</span></button>
              </div>
          </div>
          <div class="full-story__wrapper">
              ${storyData.header_image ? `<div class="header">${storyData.header_image}</div>` : ''}
             
              <div class="content">
                  ${storyData.story}
              </div>
          </div>
        `

        card.innerHTML = cardTemplate
        patientStoriesWrapper.appendChild(card)
      })

      const stories = document.querySelectorAll('.patient-stories__section .card')
      stories.forEach(story => {
        const button = story.querySelector('button')
        const fullStory = story.querySelector('.full-story__wrapper')
        const modalContent = `
        <div class="patient-stories__modal__wrapper">
          <div class="patient-stories__modal__content">
              <div class="patient-stories__modal__content">
                <div class="patient-stories__modal__close">
                  <button class="patient-stories__modal__close__button">
                    <img src="/themes/custom/envivent/assets/images/global/close-btn.svg" alt="Close" width="24" height="24" loading="lazy">
                  </button>
                </div>
                ${fullStory.outerHTML}
              </div>
            </div>
          </div>
        `
        button.addEventListener('click', ()=> {
          const modal = document.createElement('div')
          modal.classList.add('patient-stories__modal')
          modal.style.top = `${window.scrollY}px`
          modal.innerHTML = modalContent
          document.body.appendChild(modal)
          const page = document.querySelector('html')
          page.style.overflow = 'hidden'
          document.body.style.overflow = 'hidden'
    
          const closeButton = modal.querySelector('.patient-stories__modal__close__button')
          closeButton.addEventListener('click', ()=> {
            document.body.removeChild(modal)
            page.style.overflow = 'auto'
            document.body.style.overflow = 'auto'
          })
          
        })
      })
    })

   
  }
  
  // Patient Resources Page

  const isPatientResourcesPage = window.location.pathname === '/patients-and-advocacy/patient-resources'
  if (isPatientResourcesPage) {
    const resourcesHeader = document.querySelectorAll('.resource-item__header')

    resourcesHeader.forEach(header => {
      header.addEventListener('click', ()=> {
        header.parentElement.parentElement.classList.toggle('active')
      })
    })
  }

  // Search Dynamic on menu

  const menu = document.querySelector('#block-envivent-main-menu .menu')
  const blockTopMenu = document.querySelector('#block-topmenu .menu')
  const menuContainer = document.querySelector('.menu-container')
  const searchWrapper = document.createElement('li')
  searchWrapper.classList.add('search__wrapper')
  let searchInput = null

  const createButton = () => {
    const searchBtnTemplate = `
      <button class="search__button">
        <img src="/themes/custom/envivent/assets/images/global/search-icon.svg" alt="Search" class="search__icon">
      </button>
      <div class="search__input_wrapper">
        <button class="search-close__btn">
          <img src="/themes/custom/envivent/assets/images/global/close-btn.svg" alt="Close" width="8" height="8" loading="lazy">
        </button>
        <input type="text" class="search__input">
      </div>
    `
    searchWrapper.innerHTML = searchBtnTemplate
    window.innerWidth < 1024 ? blockTopMenu.appendChild(searchWrapper) : menu.appendChild(searchWrapper)

    const searchBtn = searchWrapper.querySelector('.search__button')
    const searchInputWrapper = searchWrapper.querySelector('.search__input_wrapper')
    searchInput = searchWrapper.querySelector('.search__input')
    const searchCloseBtn = searchWrapper.querySelector('.search-close__btn')

    searchBtn.addEventListener('click', ()=> {
      if (searchWrapper.classList.contains('active')) {
        handleSearch()
        return
      }
      searchWrapper.classList.add('active')
      searchInput.focus()
    })
  
    searchInput.addEventListener('input', ()=> {
      if (searchInput.value) {
        searchInput.classList.remove('error')
      }
    })
  
    searchInput.addEventListener('keypress', (e)=> {
      if (e.key === 'Enter') {
        handleSearch()
      }
    })
  
    const handleSearch = () => {
      const searchValue = searchInput.value
      if (!searchValue) {
        searchInput.classList.add('error')
        return
      }
      window.location = `/search?keys=${searchValue}`
    }
  
    searchCloseBtn.addEventListener('click', ()=> {
      searchWrapper.classList.remove('active')
      searchInput.value = ''
      searchInput.classList.remove('error')
    })
  }

  const destroyButton = () => {
    searchWrapper.remove()
  }

  createButton()
  

  window.addEventListener('resize', ()=> {
    destroyButton()
    createButton()  
    if (window.innerWidth < 1024) {
      searchWrapper.classList.add('active')
    } else {
      searchWrapper.classList.remove('active')
      searchInput.value = ''
    }
  })

  if (window.innerWidth < 1024) {
    searchWrapper.classList.add('active')
  }

  // Hover button

  const hoverBtns = document.querySelectorAll('.hover-button')

  if (hoverBtns.length) {
    hoverBtns.forEach(btn => {
      const link = btn.querySelector('a') || btn.querySelector('button')
      const img = btn.querySelector('img')
      btn.addEventListener('mouseover', ()=> {
        btn.classList.add('hover')
        
        link.addEventListener('mouseover', ()=> {
          btn.classList.add('link-active')
          link.classList.add('hover')
          img.classList.add('hover')
        })
  
        link.addEventListener('mouseleave', ()=> {
          btn.classList.remove('hover')
          btn.classList.remove('link-active')
          link.classList.remove('hover')
          img.classList.remove('hover')
        })
      })
  
      btn.addEventListener('mouseleave', ()=> {
        img.classList.remove('hover')
        link.classList.remove('hover')
        btn.classList.remove('hover')
      })
    })
  }


  // Top menu active

  const currentPath = window.location.pathname
  const topMenuItems = document.querySelectorAll('#block-topmenu li')

  if (topMenuItems.length) {
    topMenuItems.forEach(item => {
      const anchor = item.querySelector('a')
      if (anchor) {
        const parsedHref = anchor.href.replace(window.location.origin, '')
        if (anchor && currentPath.includes(parsedHref)) {
          item.classList.add('active-children')
        }
      }
    })
  } 

  // CTA

  const ctaButtons = document.querySelectorAll('.cta_banner a, .feed_header a')
  if (ctaButtons.length) {
    ctaButtons.forEach(cta => {
      const img = cta.querySelector('img')
      const cloneImg = img.cloneNode(true)
      cloneImg.classList.add('hover-image')
      cloneImg.src = "/themes/custom/envivent/assets/images/global/cta-orange-btn.png"
      cta.appendChild(cloneImg)
    })
  }

  // Media menu
  const isMediaPage = window.location.pathname === '/media-center'

  if (isMediaPage) {
    const menuTrigger = document.querySelector('.active_item.mobile-only')
    const cards = document.querySelector('.left-side .card')
    if (menuTrigger) {
      menuTrigger.addEventListener('click', ()=> {
        menuTrigger.classList.toggle('active')
        cards.classList.toggle('active')
      })
    }
  }

  // Medicine items view adjustment
  const medicinesItemsWrapper = document.querySelector('.medicines__items')
  const medicineItems = document.querySelectorAll('.medicine__item.views-row')
  if (medicinesItemsWrapper && medicineItems.length) {
    const medicineItemsView = document.querySelector('.medicine-items__view')
    console.log(medicineItemsView)
    medicinesItemsWrapper.innerHTML = ''
    medicineItems.forEach((item) => {
      const title = item.querySelector('.views-field-info').innerText
      const logo = item.querySelector('.views-field-field-logo img').outerHTML
      const body = item.querySelector('.views-field-body').outerHTML
      const instructions_of_use = item.querySelector('.views-field-field-instructions-for-use a')
      const prescribing_information = item.querySelector('.views-field-field-prescribing-information a')
      const patient_information_leaflet = item.querySelector('.views-field-field-patient-information-leafle a')
      const available_in_us = item.querySelector('.views-field-field-available-in-us').innerText.includes('True') ? true : false
      const show_every_step_button = item.querySelector('.views-field-field-show-everystep-button').innerText.includes('True') ? true : false
      const every_step_text = item.querySelector('.views-field-field-everystep-url a').innerText
      const every_step_url = item.querySelector('.views-field-field-everystep-url a').href
      const show_box_warning_message = item.querySelector('.views-field-field-show-boxed-warning-message').innerText.includes('True') ? true : false
      const site_url = item.querySelector('.views-field-field-site-url a').href

      const bodyTemplate = body ? `<div class="body-content">${body}</div>` : ''
      const siteUrlTemplate = site_url ? `<p><a class="button visit-site" href="${site_url}">Visit Site</a></p>` : ''
      const everystepTemplate = show_every_step_button ? `<p class="mobile-only"><a target="_blank" class="button every-step" href="${every_step_url}"><span>${every_step_text}</a></p><p class="desktop-only"><a class="button every-step" target="_blank" href="${every_step_url}"><span>${every_step_text}</a></p>` : ''
      const boxWarningTemplate = show_box_warning_message ? '<p class="boxed-warning">Please see full Prescribing Information including Boxed Warning</p>' : ''
      const availableInUSTemplate = available_in_us ? '' : '<p><em>Not available in the U.S.</em></p>'

      const template = `
      <div class="medicine__item">
        <div class="card accordion-item ${title.toLowerCase()} ${!available_in_us ? 'not-available-us' : '' }">
          <button class="accordion-item__header">
            <div class="image">
              ${logo ? logo : ''}
              ${availableInUSTemplate}
            </div>
          </button>
          <div class="accordion-item__body">
            <div>
              ${instructions_of_use || prescribing_information || patient_information_leaflet ? `
                <div class="copy">
                  <div class="copy-wrapper">
                    <div class="left desktop-only">
                      ${bodyTemplate}
                      ${siteUrlTemplate}
                    </div>
                    <div class="right">
                      ${prescribing_information ? `<a target="_blank" href="/medicines/${title}/${title}-FPI.pdf">Full Prescribing Information</a>` : ''}
                      ${patient_information_leaflet ? `<a target="_blank" href="/medicines/${title}/${title}-PIL.pdf">Patient Information</a>` : ''}
                      ${instructions_of_use ? `<a target="_blank" href="/medicines/${title}/${title}-IFU.pdf">Instructions for Use</a>` : ''}
                    </div>
                    <div class="left mobile-only">
                      ${bodyTemplate}
                      ${siteUrlTemplate}
                    </div>
                  </div>

                  ${everystepTemplate}
                  ${boxWarningTemplate}
                </div>
              ` : `
                <div class="copy">
                  ${bodyTemplate}
                  ${siteUrlTemplate}

                  ${everystepTemplate}
                  ${boxWarningTemplate}
                </div>
              `}
            </div>
          </div>
        </div>
      </div>
      `
      medicinesItemsWrapper.innerHTML += template
    })
    medicineItemsView.remove()
  }

  // Accordions

  const accordions = document.querySelectorAll('.accordion')
  if (accordions.length) {
    accordions.forEach(accordion => {
      const accordionButton = accordion.querySelector('button')
      const links = accordion.querySelectorAll('a')

      links.forEach(link => {
        link.setAttribute('tabindex', -1)
      })
      accordionButton.addEventListener('click', ()=> {
        accordion.classList.toggle('active')

        links.forEach(link => {
          if (link.getAttribute('tabindex') === '0') {
            link.setAttribute('tabindex', -1)
          } else {
            link.setAttribute('tabindex', 0)
          }
        })
      })
    })
  }

  const gridAccordion = document.querySelectorAll('.accordion-item')

  if (gridAccordion) {
    gridAccordion.forEach(item => {
      const button = item.querySelector('.accordion-item__header')
      const content = item.querySelector('.accordion-item__body')
      const links = item.querySelectorAll('a')

      links.forEach(link => {
        link.setAttribute('tabindex', -1)
      })

      button.addEventListener('click', ()=> {
        button.classList.toggle('active')
        content.classList.toggle('active')

        links.forEach(link => {
          if (link.getAttribute('tabindex') === '0') {
            link.setAttribute('tabindex', -1)
          } else {
            link.setAttribute('tabindex', 0)
          }
        })
      })
    })
  }


  // Initialize Modal Class
  new Modal()
  // Prevent scroll to modal after click
  $('a').each(function () {
    const href = $(this).attr('href')
    if (!href || href === '#') return

    if (
      typeof href !== 'undefined'
      && href.startsWith('#')
      && $(href).hasClass('block--type--modal')
    ) {
      $(this).on('click', (e)=> e.stopImmediatePropagation())
    }
  })
})




